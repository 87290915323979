.hero {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: var(--bg-color);
    padding-top: 80px;
    /* padding-bottom: 80px; */
}

.hero .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--space-2xl);
}

.hero .title-wrapper .title {
    display: flex;
    flex-direction: column;
}

.hero .title-wrapper .title h4 .highlight {
    color: var(--accent);
    display: block;
    white-space: nowrap;
    margin-bottom: var(--space-xs);
}

.hero .title-wrapper .title p {
    margin-bottom: var(--space-l);
}

.hero .title-wrapper .title .buttons {
    display: flex;
    column-gap: var(--space-2xs);
}

.hero .image-wrapper {
    height: calc(100vh - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--space-l);
}

.hero .image-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.hero .decoration {
    top: 80px;
    left: 0;
    translate: -50% -50%;
}

@media (max-width: 1400px) {
    .hero {
        flex-direction: column;
        padding-bottom: 0;
    }

    .hero .image-wrapper {
        height: 100%;
        padding: var(--space-l);
    }

    .hero .image-wrapper img {
        border-radius: 20px;
    }

    .hero .title-wrapper { 
        padding: var(--space-2xl) var(--space-s);
    }
}

@media screen and (max-width: 960px) {
    .hero .image-wrapper {
        padding: var(--space-s);
    }
}

@media screen and (max-width: 800px) {
    .hero .image-wrapper img {
        aspect-ratio: 1.2;
    }
}