.media {
    min-height: 100vh;
    width: 100%;
    background-color: var(--bg-color);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.media .header {
    width: 100%;
    min-height: min(360px, 100vh);
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end
}

.media .header .overlay {
    background-color: var(--overlay);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
}

.media .header img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
}

.media .header h4 {
    color: var(--bg-color);
    z-index: 2;
    width: 100%;
    max-width: 1400px;
    padding: var(--space-l);
}

.media .folders {
    width: 100%;
    max-width: 1400px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: var(--space-2xs);
    padding: 60px var(--space-l);
}

.media .folders .folder {
    position: relative;
    width: 100%;
    aspect-ratio: 1;
    background-color: var(--primary-color);
    color: var(--bg-color);
    border: transparent;
    overflow: hidden;
}

.media .folders .folder .image-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    transition: 0.5s;
}

.media .folders .folder .image-wrapper:hover {
    transform: scale(1.1);
}

.media .folders .folder .overlay {
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
}

.media .folders .folder img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
}

.media .folders .folder h6 {
    position: absolute;
    bottom: var(--space-s);
    left: var(--space-s);
    z-index: 3;
}

.media .folders .folder img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    object-fit: cover;
}

.media .folder .button-link {
    width: 100%;
    height: 100%;
}

.media .title {
    min-height: min(240px, 100vh);
    width: calc(100% - 2* var(--space-s));
    max-width: calc(1400px - 2* var(--space-s));
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding: var(--space-m);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 60px;
    position: relative;
}

.media .buttons {
    width: 100%;
    max-width: 1400px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--space-l);
    position: sticky;
    top: 80px;
    background-color: #f8f8f8ee;
    z-index: 1;
}

.media .buttons button {
    background-color: transparent;
    border: transparent;
    cursor: pointer;
}

.media .buttons .icon {
    color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
}

.media .images {
    max-width: 1400px;
    columns: 300px;
    gap: var(--space-2xs);
    padding: 0 var(--space-s) var(--space-xl);
}

.media .images .img-wrapper {
    width: 100%;
    margin-bottom: var(--space-2xs);
    /* border-radius: 8px; */
    overflow: hidden;
}

.media .images .img-wrapper img {
    width: 100%;
    display: block;
    transition: 0.5s;
}

.media .images .img-wrapper img:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.loading {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.media .media-modal {
    position: fixed;
    top: calc(50% + 40px);
    left: 50%;
    translate: -50% -50%;
    min-width: 100%;
    height: calc(100% - 80px);
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
    z-index: 100;
}

.media .close-media-modal {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
    color: white;
    cursor: pointer;
    width: fit-content;
    padding: var(--space-2xs);
    aspect-ratio: 1;
    border-radius: 50%;
    border: transparent;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    z-index: 100;
}

.media .close-media-modal:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.media .prev, .next {
    position: absolute;
    top: 50%;
    background: transparent;
    border: none;
    color: white;
    cursor: pointer;
    width: fit-content;
    padding: var(--space-2xs);
    aspect-ratio: 1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    z-index: 100;
}

.media .prev {
    left: 20px;
    padding-right: 12px;
}

.media .next {
    right: 20px;
    padding-left: 12px;
}

.media .prev:hover, .next:hover {
    background-color: rgba(255, 255, 255, 0.2);
}


@media (max-width: 600px) {
    .media .header h4 {
        padding: var(--space-l) var(--space-s);
    }

    .media .folders {
        padding: var(--space-l) var(--space-s);
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 960px) {
    .media .buttons {
        top: 60px;
    }
}