.clergy {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    background-color: var(--bg-color);
    position: relative;
    overflow: hidden;
    padding: 8rem 0;
}

.clergy .col {
    width: 50%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 var(--space-xl);
}

.clergy .col h4 {
    margin-bottom: var(--space-m);
}

.clergy .col p {
    max-width: 600px;
}

.clergy .col .image {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}

.clergy .col .image img {
    width: 100%;
    aspect-ratio: 1.5;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.clergy .decoration:nth-child(1) {
    top: 0;
    right: 0;
    translate: 50% -50%;
}
.intro .decoration:nth-child(2) {
    bottom: 0;
    right: 0;
    translate: 50% 50%;
}

@media screen and (max-width:960px) {
    .clergy {
        flex-direction: column;
        min-height: 0;
        height: 100%;
        padding: 4rem 0;
        padding-bottom: 160px;
    }

    .clergy .col {
        width: 100%;
        max-width: 600px;
        padding: 0 var(--space-m);
    }

    .clergy .col .image {
        margin-top: var(--space-xl);
    }
}