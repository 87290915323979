@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400..700&display=swap");

:root {
  --bg-color: #f8f8f8;
  --bg-dark: #f2f2f2;
  --text-color: #020301;
  --primary-color: #063970;
  --primary-dark: #0f1d44;
  --primary-light: #33477e;
  --accent: #e7a95a;
  --accent-dark: #c58634;
  --overlay: rgba(0, 0, 0, 0.6);

  --step--2: clamp(0.5556rem, 0.7143rem + -0.0992vw, 0.6944rem);
  --step--1: clamp(0.8333rem, 0.8333rem + 0vw, 0.8333rem);
  --step-0: clamp(1rem, 0.9643rem + 0.1786vw, 1.25rem);
  --step-1: clamp(1.2rem, 1.1036rem + 0.4821vw, 1.875rem);
  --step-2: clamp(1.44rem, 1.2439rem + 0.9804vw, 2.8125rem);
  --step-3: clamp(1.728rem, 1.3722rem + 1.7791vw, 4.2188rem);
  --step-4: clamp(2.0736rem, 1.4658rem + 3.0389vw, 6.3281rem);
  --step-5: clamp(2.4883rem, 1.4878rem + 5.0028vw, 9.4922rem);
  --step-6: clamp(2.986rem, 1.3785rem + 8.0374vw, 14.2383rem);

  /* @link https://utopia.fyi/space/calculator?c=320,16,1.2,2560,20,1.5,6,2,&s=0.75|0.5|0.25,1.5|2|3|4|4,s-l&g=s,l,xl,12 */

  --space-3xs: clamp(0.25rem, 0.2411rem + 0.0446vw, 0.3125rem);
  --space-2xs: clamp(0.5rem, 0.4821rem + 0.0893vw, 0.625rem);
  --space-xs: clamp(0.75rem, 0.7232rem + 0.1339vw, 0.9375rem);
  --space-s: clamp(1rem, 0.9643rem + 0.1786vw, 1.25rem);
  --space-m: clamp(1.5rem, 1.4464rem + 0.2679vw, 1.875rem);
  --space-l: clamp(2rem, 1.9286rem + 0.3571vw, 2.5rem);
  --space-xl: clamp(3rem, 2.8929rem + 0.5357vw, 3.75rem);
  --space-2xl: clamp(4rem, 3.8571rem + 0.7143vw, 5rem);
  --space-3xl: clamp(4rem, 3.8571rem + 0.7143vw, 5rem);

  /* One-up pairs */
  --space-3xs-2xs: clamp(0.25rem, 0.1964rem + 0.2679vw, 0.625rem);
  --space-2xs-xs: clamp(0.5rem, 0.4375rem + 0.3125vw, 0.9375rem);
  --space-xs-s: clamp(0.75rem, 0.6786rem + 0.3571vw, 1.25rem);
  --space-s-m: clamp(1rem, 0.875rem + 0.625vw, 1.875rem);
  --space-m-l: clamp(1.5rem, 1.3571rem + 0.7143vw, 2.5rem);
  --space-l-xl: clamp(2rem, 1.75rem + 1.25vw, 3.75rem);
  --space-xl-2xl: clamp(3rem, 2.7143rem + 1.4286vw, 5rem);
  --space-2xl-3xl: clamp(4rem, 3.8571rem + 0.7143vw, 5rem);

  /* Custom pairs */
  --space-s-l: clamp(1rem, 0.7857rem + 1.0714vw, 2.5rem);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;
  /* line-height: 1.2; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  font-size: var(--step-0);
  font-weight: 300;
  opacity: 0.7;
}
h6 {
  font-size: var(--step-1);
  font-weight: 400;
}
h5 {
  font-size: var(--step-2);
  font-weight: 400;
}
h4 {
  font-size: var(--step-3);
  font-weight: 400;
}
h3 {
  font-size: var(--step-4);
  /* font-weight: 500; */
}
h2 {
  font-size: var(--step-5);
  /* font-weight: 500; */
}
h1 {
  font-size: var(--step-6);
  /* font-weight: 500; */
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.button {
  position: relative;
  width: fit-content;
  padding: 8px 16px;
  font-size: var(--step-0);
  border: 1px solid transparent;
  border-radius: 50px;
  cursor: pointer;
  transition: 0.4s;
  color: var(--bg-color);
  font-family: "Montserrat";
}

.button.filled {
  background-color: var(--primary-color);
}

.button.empty {
  background-color: transparent;
  border: 1px solid #06397055;
  color: var(--text-color);
}

.button.empty:hover {
  border: 1px solid #063970;
}

.button.filled:hover {
  background-color: var(--primary-light);
}

.decoration {
  width: 100%;
  max-width: 1000px;
  position: absolute;
  aspect-ratio: 1;
  background-image: url("./images/checkeredBG.png");
  border-radius: 50%;
  opacity: 0.1;
}

.decoration::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: var(--bg-color);
  background: radial-gradient(
    circle,
    rgba(248, 248, 248, 0) 0%,
    rgba(248, 248, 248, 1) 70%
  );
}
