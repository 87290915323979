.mission {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--bg-color);
    padding: 0 0 120px;
    z-index: 0;
    overflow: hidden;
}

.mission .statement {
    width: 100%;
    height: max(40vh, 300px);
    max-width: 1000px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 var(--space-m);
    z-index: 100;
}

.mission .image-wrapper {
    width: 150px;
    aspect-ratio: 1;
    position: absolute;
    border-radius: 50%;
    overflow: hidden;
}

.mission .image-wrapper img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.mission .decoration {
    top: 0%;
    right: 0;
    translate: 50% -50%;
}
