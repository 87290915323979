.timeline {
    display: flex;
    justify-content: center;
    flex-direction: row;
    background-color: var(--bg-color);
}

.timeline .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    max-width: 800px;
    padding: 0 var(--space-m);
    color: var(--text-color);
}

.timeline .left {
    width: 50%;
    height: 100vh;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: sticky;
    top: 0;
    padding: var(--space-m);
}

.timeline .right .desktop-content-section {
    max-width: 600px;
	min-height:100vh;
	display:flex;
	flex-direction:column;
	justify-content:center;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: var(--space-xl);
}

.timeline .right .desktop-content-section .desktop-content {
    width: 100%;
}

.timeline .right .desktop-content-section .desktop-content h4 {
    margin-bottom: var(--space-m);
    position: relative;
}

.timeline .right .desktop-content-section .desktop-content h4::before {
    content: "";
    position: absolute;
    top: 50%;
    left: calc(-1 * var(--space-xl));
    translate: -50% -50%;
    width: 20px;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: var(--bg-color);
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.1);
}

.timeline .left .desktop-photos {
	width: 100%;
    height: 100%;
    max-width: 800px;
	aspect-ratio: 1.5;
	position:relative;
	overflow:hidden;
}

.timeline .left .desktop-photos .desktop-photo {
	position:absolute;
    top: calc(50% + 40px);
    translate: 0 -50%;
    object-fit: cover;
	width: 100%;
    aspect-ratio: 1.5;
    border-radius: 20px;
}

.timeline .mobile-photo {
	width: 100%;
    aspect-ratio: 1.5;
	margin-top: var(--space-m);
    display: none;
    overflow: hidden;
    border-radius: 20px;
}

.timeline .mobile-photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width: 600px) {
	.timeline .left {
		display:none;
	}

    .timeline .right {
        width: 100%;
    }

    .timeline .right .desktop-content-section {
        min-height: 0;
        height: 100%;
        padding-bottom: 120px;
    }

    .timeline .mobile-photo {
        display: block;
    }
}