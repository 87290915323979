/* Curtain overlay */
.curtain {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("https://saintmarychurch.s3.amazonaws.com/images/12aFpplmgYs4QV1KXf1Ptm62G-KY4_tcO")
    no-repeat center center;
  background-size: cover;
  z-index: 9999; /* Curtain sits above the content */
  transform: translateX(0); /* Initial position covers the screen */
  transition: transform 2s ease-in-out; /* Smooth sliding animation */
}

.curtain.open {
  transform: translateX(100%); /* Slide out to the right */
}
