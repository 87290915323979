.slider {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin: auto;
  }
  
.slider .slider-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.slider .slider-container .image-wrapper {
    flex-shrink: 0;
    flex-grow: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.slider .slider-container .image-wrapper img {
    width: 90%;
    height: 80%;
    object-fit: contain;
}
  
.cycle {
    position: absolute;
    top: 50%;
    font-size: 24px;
    background: transparent;
    border: none;
    color: white;
    cursor: pointer;
    width: fit-content;
    aspect-ratio: 1;
    border-radius: 50%;
    z-index: 100;
    transform: translateY(-50%);
    padding: var(--space-2xs);
    display: flex;
    align-items: center;
    justify-content: center;
}

.cycle:hover {
    background-color: rgba(255, 255, 255, 0.2);
}
  
.cycle.prev {
    padding-right: 12px;
    left: 10px;
}
  
.cycle.next {
    padding-left: 12px;
    right: 10px;
}

@media screen and (max-width: 600px) {
    .slider .slider-container .image-wrapper {
        padding: var(--space-2xs);
    }

    .slider .slider-container .image-wrapper img {
        max-width: 100%;
        max-height: 100%;
    }
}
  