ul {
  list-style-type: none;
}
ul li.tab-item {
  padding: 10px 20px;
}

.career-list_link {
  padding: 18px;
  display: block;
}
.career-list_item {
  border: 1px solid rgba(37, 37, 37, 0.1);
  border-radius: 4px;
  margin: 8px auto;
  transition: transform 0.3s, box-shadow 0.3s;
}
.career-list_item:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 8px -6px rgba(37, 37, 37, 0.08);
}
.career-list_item:hover .career-list_tag {
  background-color: #77dd66;
  color: #ffffff;
}
.career-list_item:hover .career-list_title {
  color: #77dd66;
}
.career-list_title {
  font-weight: 500;
  color: #252525;
  margin-bottom: 16px;
  font-size: 18px;
  transition: color 0.2s;
}
.career-list_location {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Roboto Mono", monospace;
}
.career-list_tag {
  margin-left: auto;
  border-radius: 10px;
  color: rgba(37, 37, 37, 0.6);
  background-color: #f0f0f0;
  font-size: 11px;
  text-align: center;
  font-weight: 400;
  font-family: "Roboto Mono", monospace;
  min-width: 88px;
  padding: 4px;
  text-transform: uppercase;
  transition: background-color 0.2s, color 0.2s;
}
.career-list_icon {
  margin-right: 0.3em;
}

.career-location_info {
  display: flex;
  align-items: center;
}

.career-location_text {
  font-size: 13px;
  color: rgba(37, 37, 37, 0.6);
}

.career-list_icon {
  fill: rgba(37, 37, 37, 0.2);
}

.career-selector_list {
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 32px;
  margin-bottom: 24px;
}

.career-selector {
  display: none;
  position: absolute;
  width: 100%;
  top: 45px;
  background-color: #f0f0f0;
  z-index: 1;
  border: 1px solid rgba(37, 37, 37, 0.1);
  border-top: none;
  padding-left: 24px;
}
.career-selector_link {
  display: block;
  color: rgba(37, 37, 37, 0.8);
}
.career-selector_link:hover {
  color: #77dd66;
  cursor: pointer;
}

.career-selector_item {
  line-height: 40px;
}

.career-selected {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  line-height: 48px;
  border-radius: 4px;
  border: 1px solid rgba(37, 37, 37, 0.1);
}
.career-selected span {
  color: rgba(37, 37, 37, 0.8);
}
.career-selected:hover span {
  cursor: default;
  color: #252525;
}

.dropdown-arrow {
  margin-right: 22px;
}

.career-selected span {
  margin-left: 24px;
}

.career-pressed {
  transform: scaleY(-1);
}

@media (min-width: 360px) {
  .career-list_tag {
    padding: 4px 8px;
  }
}
@media (min-width: 767px) {
  .career-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .career-list_item {
    margin-bottom: 0;
    margin-top: 16px;
    margin-left: 0;
    margin-right: 0;
    flex-basis: 49%;
  }
  .career-list_link {
    padding: 24px 22px;
  }
  .career-list_title {
    font-size: 16px;
  }

  .career-location_text {
    font-size: 12px;
  }
}
@media (min-width: 992px) {
  .career-list_title {
    font-size: 18px;
  }

  .career-location_text {
    font-size: 14px;
  }
}
/* ------------------------------------ *\
    # Styles REQUIRED for JS
  \* ------------------------------------ */
.career-selector_item[data-current="true"] {
  display: none;
}

.tab-item[data-current="true"] {
  color: #77dd66;
  border-bottom: 2px solid #77dd66;
}

.selected-category {
  text-transform: capitalize;
}

.tab-list {
  display: none;
}
@media (min-width: 767px) {
  .tab-list {
    display: flex;
  }
}

.tab-item {
  cursor: pointer;
  padding-bottom: 12px;
}
.tab-item:hover {
  color: #77dd66;
}

.career-no {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 16px;
  width: 100%;
  text-align: center;
}
.career-no_text {
  width: 100%;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 24px;
  padding-right: 24px;
  background-color: #f0f0f0;
  border-radius: 4px;
  margin-bottom: 16px;
  color: rgba(37, 37, 37, 0.6);
}
.career-no_second {
  width: 100%;
  display: block;
}
.career-no_second use {
  transform: rotate(90deg);
  transform-origin: 14px 8px;
}
