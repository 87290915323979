.footer {
    width: 100%;
    height: 100%;
    background-color: var(--primary-dark);
    color: var(--bg-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    align-self: flex-end;
    padding: 100px var(--space-m);
}

.footer p {
    opacity: 0.8;
}

.footer .container {
    max-width: 1440px;
    display: flex;
    flex-direction: row;
    border-top: 1px solid var(--bg-color);
    border-bottom: 1px solid var(--bg-color);
    padding: var(--space-l) 0;
    margin: 0 0 var(--space-l);
}

.footer .container .logo {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: left;
}

.footer .container .logo a {
    transition: 0.3s;
}

.footer .container .logo a:hover {
    color: var(--accent);
}

.footer .container .info {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: left;
}

.footer .container .info .item {
    width: 100%;
    display: inline-flex;
    margin-bottom: var(--space-s);
}

.footer .container .info .item {
    display: inline-flex;
    column-gap: var(--space-2xs);
    align-items: center;
    margin: var(--space-2xs) 0;
    transition: 0.3s;
}

.footer .container .info .item:hover {
    color: var(--accent);
}

.footer .container .info .button {
    margin-top: var(--space-m);
}

.bold {
    font-weight: 700;
}

.no-bold {
    display: hidden;
}

.footer .container .info span .icon {
    margin: 0 var(--space-2xs) 0 0;
    min-width: 24px;
}

.footer .bottom {
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: var(--space-2xs);
}

.footer .bottom .links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.footer .bottom .social {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer .bottom li {
    transition: 0.3s;
    cursor: pointer;
}

.footer .bottom li:hover {
    color: var(--accent);
}

.footer .bottom .social li {
    margin: 0 var(--space-2xs);
}

.footer .bottom .links li {
    margin: 0 var(--space-s);
}

.footer a {
    text-decoration: none;
    color: inherit;
    width: fit-content;
}

.footer .button.filled {
    background-color: var(--accent);
    color: var(--primary-dark);
}

.footer .button.filled:hover {
    background-color: var(--accent-dark);
}

@media screen and (max-width: 960px) {
    .footer .container {
        flex-direction: column;
        row-gap: var(--space-xl);
        padding: var(--space-xl) var(--space-s);
    }

    .footer .container .logo {
        width: 100%;
    }
    
    .footer .container .info {
        width: 100%;
    }

    .footer .bottom {
        flex-direction: column;
        padding: var(--space-s);
        row-gap: var(--space-s);
    }
}