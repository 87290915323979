.infinite-wrapper {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 35vh;
    min-height: 200px;
    display: flex;
    align-items: center;
}

.infinite-wrapper:before,
.infinite-wrapper:after {
  position: absolute;
  top: 0;
  width: 10%;
  height: 100%;
  content: "";
  z-index: 2;
}

.infinite-wrapper:before {
  left: 0;
  background: linear-gradient(to left, #f2f2f200, #f2f2f2);
}

.infinite-wrapper:after {
  right: 0;
  background: linear-gradient(to right, #f2f2f200, #f2f2f2);
}

.infinite-wrapper .infinite-slider {
    display: flex;
    align-items: center;
    height: 100%;
    animation: slide 40s linear infinite;
    width: max-content;
}

.infinite-wrapper .infinite-slider img {
    height: 90%;
    margin: 0 16px;
    aspect-ratio: 1.5;
    /* border-radius: 50%; */
    object-fit: cover;
    border-radius: 20px;
    transition: 0.3s;
}

.infinite-wrapper .infinite-slider img:nth-child(even) {
    rotate: 3deg;
}
.infinite-wrapper .infinite-slider img:nth-child(odd) {
    rotate: -3deg;
}

.infinite-wrapper .infinite-slider img:hover {
    rotate: 0deg;
    scale: 1.1;
}

.infinite-wrapper .infinite-slider.reverse {
    animation: slide-reverse 40s linear infinite;
}

@keyframes slide {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-50%);
    }
}

@keyframes slide-reverse {
    from {
        transform: translateX(-50%);
    }
    to {
        transform: translateX(0%);
    }
}

/* @media screen and (max-width: 700px) {
    .infinite-wrapper .infinite-slider img {
        max-height: 120px;
        margin: 0 20px;
    }
} */
