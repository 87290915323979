.col-section {
    background-color: var(--bg-color);
    padding: 8rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 160px;
    position: relative;
    z-index: 99;
    overflow: hidden;
    box-sizing: border-box;
}

.col-section .row {
    width: 100%;
    max-width: 2000px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.col-section .row .col {
    width: 50%;
    max-width: 800px;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: stretch;
    padding: 0 var(--space-xl);
    z-index: 1;
    text-shadow: var(--bg-color) 0px 0px 40px;
}

.col-section .row .col .text {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}

.col-section .row .col p {
    margin-bottom: var(--space-l);
    color: var(--text-color);
}

.col-section .row .col h4 {
    margin-bottom: var(--space-s);
    color: var(--text-color);
}

.col-section .row .col h4 .highlight {
    color: var(--accent);
}

.col-section .row .col .image {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.col-section .row .col .image img {
    width: 100%;
    aspect-ratio: 1.5;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.col-section .link {
    color: var(--text-color);
    position: relative;
    width: fit-content;
    font-weight: 500;
    z-index: 1;
    font-size: var(--step-0);
}

.col-section .link span {
    display: inline-block;
    margin-left: var(--space-2xs);
    transition: 0.5s;
}

.col-section .link::after {
    content: "";
    width: 0;
    height: 1px;
    background-color: var(--text-color);
    opacity: 1;
    position: absolute;
    bottom: -4px;
    left: 0;
    z-index: -1;
    transition: 0.5s;
}

.col-section .link:hover span {
    translate: 4px;
}

.col-section .link:hover::after {
    width:100%;
}

.col-section .decoration:nth-child(1) {
    top: 0;
    right: 0;
    translate: 50% -50%;
}
.col-section .decoration:nth-child(2) {
    top: 50%;
    left: 0;
    translate: -50% -50%;
}
.col-section .decoration:nth-child(3) {
    top: 100%;
    right: 0;
    translate: 50% -50%;
}


@media screen and (min-width:960px) {
    .col-section .row:nth-child(even) {
        flex-direction: row;
    }

    .col-section .row:nth-child(odd) {
        flex-direction: row-reverse;
    }
}

@media screen and (max-width:960px) {
    .col-section {
        row-gap: 120px;
    }

    .col-section .row {
        flex-direction: column-reverse;
    }

    .col-section .row .col {
        width: 100%;
        padding: 0 var(--space-m);
    }

    .col-section .row .col .text h4 {
        margin-bottom: var(--space-xs);
    }
    
    .col-section .row .col .text p {
        margin-bottom: var(--space-m);
    }

    .col-section .row .col .image {
        max-width: 600px;
        margin-top: var(--space-l);
    }
}
