.shopDivisor {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
  color: var(--bg-color);
  padding: var(--space-l);
  z-index: 100;
}

.shopDivisor .container {
  width: 100%;
  max-width: 1440px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: var(--space-2xl);
  margin: var(--space-2xl) 0;
  z-index: 1;
  column-rule: 1px solid var(--bg-color);
}

.shopDivisor .container .card {
  position: relative;
  max-width: 400px;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  text-align: left;
  border-left: 1px solid var(--bg-color);
  padding: var(--space-2xs) var(--space-l);
}

.shopDivisor .container .card .icon {
  margin-bottom: var(--space-s);
}

.shopDivisor .container .card h5 {
  font-size: var(--step-1);
  margin-bottom: var(--space-s);
}

.shopDivisor .container .card p {
  text-align: left;
  margin-bottom: var(--space-l);
}

.shopDivisor .container .card a {
  color: var(--accent);
  font-weight: 500;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  font-size: var(--step-0);
}

.shopDivisor .container .card a::after {
  content: "";
  width: 0;
  height: 1px;
  position: absolute;
  bottom: -4px;
  left: 0;
  background-color: var(--accent);
  transition: 0.5s;
}

.shopDivisor .container .card a:hover::after {
  width: 100%;
}

.shopDivisor .container .card a span {
  display: inline-block;
  margin-left: var(--space-2xs);
  transition: 0.5s;
}

.shopDivisor .container .card a:hover > span {
  translate: 4px;
}
