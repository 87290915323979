.search-box,
.close-icon,
.search-wrapper {
  position: relative;
  padding: 10px;
}
.search-wrapper {
  width: 500px;
  margin: auto;
  margin-top: 50px;
}
.search-box {
  width: 80%;
  border: 1px solid #ccc;
  outline: 0;
  border-radius: 15px;
}
.search-box:focus {
  box-shadow: 0 0 15px 5px #b0e0ee;
  border: 2px solid #bebede;
}
.close-icon {
  border: 1px solid transparent;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
}
.close-icon:after {
  content: "X";
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  background-color: #fa9595;
  z-index: 1;
  right: 35px;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 2px;
  border-radius: 50%;
  text-align: center;
  color: white;
  font-weight: normal;
  font-size: 12px;
  box-shadow: 0 0 2px #e50f0f;
  cursor: pointer;
}
.search-box:not(:valid) ~ .close-icon {
  display: none;
}
